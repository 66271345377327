import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PildoraComponent } from './components/pildora/pildora.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalPruebaVidaComponent } from './components/modal-prueba-vida/modal-prueba-vida.component';
import { LoaderComponent } from './components/loader/loader.component';
import { WebcamModule } from 'ngx-webcam';
import { CustomScrollDirective } from './directives/custom-scroll.directive';
import { MatPaginatorIntlCustom } from './services/MatPaginatorIntlCustom';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ModalDniComponent } from './components/modal-dni/modal-dni.component';
import { DndDirective } from './directives/dnd.directive';
import { ModalArchivosComponent } from './components/modal-archivos/modal-archivos.component';
import { MatButtonModule } from '@angular/material/button';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { CardDesplegableComponent } from './components/card-desplegable/card-desplegable.component';

@NgModule({

  declarations: [
    ModalPruebaVidaComponent,
    PildoraComponent,
    LoaderComponent,
    ModalDniComponent,
    DndDirective,
    CustomScrollDirective,
    ModalArchivosComponent,
    CardDesplegableComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    WebcamModule,
    MatButtonModule,
    MatExpansionModule,
    MatCardModule,
    MatDividerModule
  ],
  exports: [
    ModalPruebaVidaComponent,
    CustomScrollDirective,
    PildoraComponent,
    CommonModule,
    ModalDniComponent,
    CardDesplegableComponent,
    DndDirective
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCustom }],
})
export class CoreModule { }
